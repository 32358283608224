<template>
  <div class="h-full">
    <UserProfile />
    <NavigationButtonCustomer />
  </div>
</template>

<script>
import UserProfile from "../../components/UserProfile";
import NavigationButtonCustomer from '../../components/navigationButton/CustomerButton'

export default {
  name: "ProfileCustomer",
  components: {
    UserProfile,
    NavigationButtonCustomer
  }

}
</script>

<style scoped>

</style>