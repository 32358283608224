<template>
  <div class="overflow-y-scroll scrollbar-hide h-full">
    <div class="relative m-auto z-10 mb-32">
      <div class="top-profile">
        <div class="bg-profile"></div>
        <img :src="baseURL + user.profile" alt="" class="rounded-full w-24 mx-auto">
        <h5 >پروفایل</h5>
      </div>
      <div class="mx-4 mb-5">
        <div class="box-profile">
          <label class="block relative ">
            <span class="text-white opacity-50 text-sm">نام </span>
            <input
                type="text"
                v-model="firstName"
                class="mt-0 block text-white w-full pl-0.5 py-1.5 border-0 border-b border-white">
          </label>
          <label class="block relative mt-4">
            <span class="text-white opacity-50 text-sm">نام خانوادگی </span>
            <input
                v-model="lastName"
                type="text"
                class="mt-0 block text-white w-full pl-0.5 py-1.5 border-0  border-b border-white">
          </label>
          <div class="block relative mt-7">
            <span class="text-white opacity-50 text-sm">لوگو</span>
            <span class="absolute top-8 right-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.13" height="11.988" viewBox="0 0 13.13 11.988">
              <path id="ic_add_a_photo_24px"
                    d="M1.713,2.713V1H2.854V2.713H4.567V3.854H2.854V5.567H1.713V3.854H0V2.713ZM3.425,6.138V4.425H5.138V2.713h4l1.045,1.142h1.81A1.145,1.145,0,0,1,13.13,5v6.85a1.145,1.145,0,0,1-1.142,1.142H2.854a1.145,1.145,0,0,1-1.142-1.142V6.138Zm4,5.138A2.854,2.854,0,1,0,4.567,8.421,2.855,2.855,0,0,0,7.421,11.275ZM5.594,8.421A1.827,1.827,0,1,0,7.421,6.594,1.825,1.825,0,0,0,5.594,8.421Z"
                    transform="translate(0 -1)" fill="#ff7400"/>
            </svg>
        </span>
            <div class="images-profile">
              <img class="w-24 h-24 inline-block" :src="[ProfilePreview !== null ? ProfilePreview : baseURL +  user.profile]" alt="">
              <validation-provider name="عکس پروفایل" rules="ext:jpg,png,jpeg,gif" ref="imageInput" v-slot="{ errors, validate }">
                <div class="logo-upload">
                  <input
                      type="file"
                      @change="onFileSelected"
                      class="file-upload-logo mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30.884" height="28.894" viewBox="0 0 22.884 20.894">
                    <path id="ic_add_a_photo_24px" d="M2.985,3.985V1h1.99V3.985H7.959v1.99H4.975V8.959H2.985V5.975H0V3.985ZM5.97,9.954V6.97H8.954V3.985h6.965l1.821,1.99h3.154a2,2,0,0,1,1.99,1.99V19.9a2,2,0,0,1-1.99,1.99H4.975a2,2,0,0,1-1.99-1.99V9.954Zm6.965,8.954a4.975,4.975,0,1,0-4.975-4.975A4.976,4.976,0,0,0,12.934,18.909ZM9.75,13.934a3.184,3.184,0,1,0,3.184-3.184A3.18,3.18,0,0,0,9.75,13.934Z" transform="translate(0 -1)" fill="#ff7400"/>
                  </svg>
                </div>
              </validation-provider>
            </div>
          </div>

        </div>
        <a class="btn-custom mt-4" @click="finish">
          ثبت تغییرات
          <span class="svg-btn-icon2 bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>
        </span>
        </a>
        <a class="btn-custom mt-4" @click="logout">
          خروج
          <span class="svg-btn-icon2 bg-red transform -rotate-90">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
        </a>

      </div>

    </div>
  </div>
</template>

<script>
import Uploader from 'vux-uploader-component'
import axios from "../plugins/axios";
import {getBase64Strings} from "exif-rotate-js";
export default {
  name: "UserProfile",
  components:{
    Uploader
  },
  data(){
    return{
      ProfileSelected: null,
      imageSrc : [],
      ProfilePreview :null,
      user : null,
      firstName : null,
      lastName : null,
      baseURL: this.$store.state.general.baseURL

    }
  },
  methods :{
    updateUser(){
      axios.get('/users').then(({data})=>{
        localStorage.setItem('user', JSON.stringify(data.data))
        this.user = data.data
      })
    },
    async onFileSelected(e){
      const { valid } = await this.$refs.imageInput.validate(e);
      if (valid){
        this.ProfileSelected = e.target.files[0];
        this.ProfilePreview = URL.createObjectURL(this.ProfileSelected)
        this.imageSrc = await getBase64Strings(e.target.files)
      }else{
        this.$toast.error('فرمت انتخاب شده معتبر  نمیباشد')
      }

    },
    finish(){
      if (this.firstName !== '' && this.lastName !== ''){
        axios.put('users',{
          first_name : this.firstName,
          last_name : this.lastName,
        }).then((data)=>{
          this.$toast.success('اطلاعات شما با موفقیت بروز شد')
          this.updateUser()
        })
      }
      if (this.ProfileSelected !== null){
        axios.put('users', { profile : this.imageSrc[0] }).then((data)=>{
          this.$toast.success('پروفایل شما با موفقیت بروز شد')
          this.updateUser()
        })
      }
    },
    logout(){
      axios.post('/logout').then(()=>{
        localStorage.removeItem('user')
        localStorage.removeItem('user-token')
        this.$router.push('/')
      })

    },
  },
  created() {
    let user = JSON.parse(localStorage.getItem('user'))
    this.user = user
    this.firstName = user.first_name
    this.lastName = user.last_name
  }
}
</script>

<style scoped>
.upload-component{
  padding: unset ;
}
.upload-component >>> .vux-uploader_hd{
  display: none !important;
}
.upload-component >>> .vux-uploader_input-box{
  float: right !important;
  background: rgba(255,255,255,0.2);
  border-radius: 1.5rem;
  border: none;
}
.upload-component >>> .vux-uploader_input-box::after{
  content: '';
  background: url("../assets/images/icons/shot_image.svg") center center no-repeat;
  width: 100%;
  height: 100%;
}
.upload-component >>> .vux-uploader_input-box::before{
  all: revert;
}
.upload-component >>> .vux-uploader_file{
  float: right !important;
  border-radius: 1.5rem;
}
</style>